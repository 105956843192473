<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Categories Groups'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Categories Group Create'"
      :title="'Categories Group Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">SEO Name</p>
          <div class="input-container">
            <InputText
              :onChange="setSeoName"
              :id="'seo_name'"
              :label="'seoName'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import { seoTransform } from "~/helpers/seoTransform";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "CategoriesGroupCreate",
  metaInfo: {
    title: "Categories Group Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
  },
  computed: {
    ...mapState("categories", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name != undefined ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name != undefined ? this.formDataToSend.seo_name.trim() : "",
        status: this.formDataToSend.status ? 1 : 0,
      };
      this.$store.dispatch("categories/createCategoriesGroup", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.CATEGORIES_GROUPS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
      this.handleCheckSeoName();
    },
    handleCheckSeoName() {
      if (this.formDataToSend.seo_name.length > 0) {
        const url = `/${this.formDataToSend.seo_name.trim()}`;
        this.$store.dispatch("categories/categoriesGroupCheckSeo", url);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
